import React, { Component } from "react";
import Footer from "../components/templates/Footer";
import Header from "../components/templates/Header";
import SideMenu from "../components/templates/SideMenu";
import XLSX from "xlsx";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Button from "react-bootstrap/Button";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import dateFormat from "dateformat";
import DatePicker from "react-datepicker";
import ProgressBar from "react-bootstrap/ProgressBar";
import Check from "react-bootstrap/FormCheck";
import Select from "react-select";

import "react-datepicker/dist/react-datepicker.css";
import { subDays } from "date-fns";
import { readCurrencyWithoutRP } from "../modules/CurrencyModule";
import { readCategoryForecast } from "../modules/CategoryForecastModule";
import { readMaterialAndEXPBySearch } from "../modules/MaterialModule";
import { readUom } from "../modules/UomModule";
import { readSegment } from "../modules/SegmentModule";
import { readCustomerAsync } from "../modules/CustomerModule";
import thousandformat from "../helpers/thousanddelimiter";
import NumberFormat from "react-number-format";

import { withRouter } from "react-router-dom";
import {
  addSalesForecas,
  editSalesForecast,
  readSalesForecas,
  overwriteSalesForecast,
  deleteSalesForecast,
  generateExcelSalesForecastConfirmation,
  createBulkSalesForecastConfirmation,
  downloadExcelSalesForecast,
  confirmBulk,
} from "../modules/SalesForecastModule";
import RemoteAll from "../components/RemoteAll";

import { sendNotification } from "../modules/NotificationModule";
import moment from "moment";
import { readGeneralSetting } from "../modules/generalSettingModule";
import { ButtonGroup, Tab, Tabs } from "react-bootstrap";

import FileDownload from "js-file-download";
import WorksheetTabCustom from "../components/WorksheetTabCustom";
import AsyncSelect from "react-select/async";

const SheetJSFT = ["xlsx", "xlsb", "xlsm", "xls", "csv"]
  .map((x) => `.${x}`)
  .join(",");

const array = new Uint32Array(10);
class SalesForecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputFile: Math.random(),
      loading_download_history: false,
      loading_download: false,
      submitted: false,
      submitted_create: false,
      showModal: false,
      showModalDailySpotPrice: false,
      loading: false,
      disableButtonNotification: false,
      seconds: 60,
      readOnly: false,
      isValidMaterial: true,
      isValidCustomer: true,
      isValidShippedTo: true,
      isValidMaterialCreate: true,
      isValidCustomerCreate: true,
      isValidShippedToCreate: true,
      currencyreadOnly: false,
      unitreadOnly: false,
      isClearable: true,
      collection: {},
      select_items: [],
      select_items_category: [],
      select_items_material: [],
      select_items_uom: [],
      select_items_segemnt: [],
      select_items_customer: [],
      list_datatable: [],
      errors: {},
      errors_create: {},
      errors_create_bulk: {},
      id: null,
      requester: null,
      requester_name: null,
      material: null,
      material_code: null,
      segment: null,
      customer: null,
      shipped_to: null,
      customer_code: null,
      shipped_to_code: null,
      qty: null,
      unit: null,
      date: null,
      year: null,
      page: 1,
      sizePerPage: 10,
      totalSize: 10,
      search: "",
      isValidMaterial_create: false,
      segment_create: null,
      customer_create: null,
      shipped_to_create: null,
      material_create: null,
      material_create_code: null,
      customer_create_code: null,
      shipped_to_create_code: null,
      category_create: null,
      qty_create: null,
      unit_create: null,
      date_create: null,
      date_first: null,
      date_second: null,
      jan_create: null,
      feb_create: null,
      mar_create: null,
      apr_create: null,
      may_create: null,
      jun_create: null,
      jul_create: null,
      aug_create: null,
      sep_create: null,
      oct_create: null,
      nov_create: null,
      dec_create: null,
      jan: null,
      feb: null,
      mar: null,
      apr: null,
      may: null,
      jun: null,
      jul: null,
      aug: null,
      sep: null,
      oct: null,
      nov: null,
      dec: null,
      cat_jan_create: null,
      cat_feb_create: null,
      cat_mar_create: null,
      cat_apr_create: null,
      cat_may_create: null,
      cat_jun_create: null,
      cat_jul_create: null,
      cat_aug_create: null,
      cat_sep_create: null,
      cat_oct_create: null,
      cat_nov_create: null,
      cat_dec_create: null,
      cat_jan: null,
      cat_feb: null,
      cat_mar: null,
      cat_apr: null,
      cat_may: null,
      cat_jun: null,
      cat_jul: null,
      cat_aug: null,
      cat_sep: null,
      cat_oct: null,
      cat_nov: null,
      cat_dec: null,
      columns: [
        {
          dataField: "date",
          text: "Year",
          classes: "text-center",
          formatter: this.GetDateFormat,
          sort: true,
        },
        {
          dataField: "segment_name",
          text: "Segment",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "customer_name",
          text: "Sold To",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "material_fg_name",
          text: "Material FG",
          classes: "text-center",
          sort: true,
        },
        {
          dataField: "qty",
          text: "Qty",
          classes: "text-center",
          formatter: this.GetThousandDelimiterFormat,
          sort: true,
        },
        {
          dataField: "unit",
          text: "Unit",
          classes: "text-center",
          sort: true,
        },

        {
          text: "Action",
          dataField: "",
          formatter: this.GetActionFormat,
          classes: "text-center",
          headerStyle: { width: "10%" },
        },
      ],
      sortBy: "segment_name",
      sortOrder: "desc",
      defaultSorted: [
        {
          dataField: "segment_name",
          order: "desc",
        },
      ],
      cellEditProps: {},
      filter_by: null,
      errors_search: {},
      isSearch: false,
      year_filter: new Date().getFullYear().toString(),
      forecast_year: [],
      isLoading: false,
      editable: {
        Jan: false,
        Feb: false,
        Mar: false,
        Apr: false,
        May: false,
        Jun: false,
        Jul: false,
        Aug: false,
        Sep: false,
        Oct: false,
        Nov: false,
        Dec: false,
      },
      editable_date_start: null,
      editable_date_end: null,
      amount_backdate_month: null,
      file: {
        name: "Choose file",
        type: "",
        size: 0,
      },
      file_value: null,
      file_object: null,
      file_worksheet: [],
      load_file: false,
      month_start: moment(new Date()).startOf("years").toDate(),
      month_end: moment(new Date()).endOf("years").toDate(),
      showModalConfirm: false,
      confirm_data: { found: [], not_found: [] },
      template: null,
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const user = JSON.parse(localStorage.getItem("user"));

    const department =
      user.details.hris_org_tree.current_person.nama_department;
    this.setState({ department });

    readCurrencyWithoutRP().then((response) => {
      this.setState({ select_items: response });
    });

    readCategoryForecast().then((response) => {
      this.setState({ select_items_category: response });
    });

    readMaterialAndEXPBySearch({
      constraint_value: "ZFGD",
      constraint: "material_type",
    }).then((response) => {
      this.setState({ select_items_material: response });
    });

    readUom().then((response) => {
      this.setState({ select_items_uom: response });
    });

    readSegment().then((response) => {
      this.setState({ select_items_segemnt: response });
    });

    readCustomerAsync({
      sizePerPage: 100,
    }).then((response) => {
      this.setState({ select_items_customer: response });
    });

    const { sortBy, sortOrder, page, sizePerPage, year_filter } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}${
      year_filter ? "&year=" + year_filter : ""
    }`;

    //read data daily spot on backend
    readSalesForecas(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        isLoading: false,
      });
    });

    readSalesForecas(`forecast_year=true`).then((response) => {
      this.setState({
        forecast_year: response,
      });
    });

    let query_setting = `user=${user.username}&module=sales_forecast&action=edit_qty`;
    readGeneralSetting(query_setting).then((response) => {
      this.setState({
        editable_date_start: response.date_start,
        editable_date_end: response.date_end,
        amount_backdate_month: response.amount_backdate_month,
      });
    });

    this.setState({
      date_create: new Date(),
      date_first: new Date(),
      date_second: subDays(new Date(), 7),
      requester: user.username,
      requester_name: user.details.hris_org_tree.current_person.person_name,
    });

    //cari hak akses
    let menu = JSON.parse(localStorage.getItem("menu"));
    const { history } = this.props;
    let current_link = null;
    let check_access = null;
    if (history.location) {
      if (history.location.pathname) {
        current_link = history.location.pathname;
      }
    }
    menu.map((item) => {
      item.details.map((e) => {
        if (e.link === current_link) {
          check_access = e;
        }
      });
    });
    if (check_access) {
      this.setState({
        isCreate: check_access.isCreate,
        isRead: check_access.isRead,
        isDelete: check_access.isDelete,
        isEdit: check_access.isEdit,
      });
    }
  }
  //change state input
  onChangeValue = (any) => {
    const { filter_by } = this.state;
    const array_name_create = [
      "jan_create",
      "feb_create",
      "mar_create",
      "apr_create",
      "may_create",
      "jun_create",
      "jul_create",
      "aug_create",
      "sep_create",
      "oct_create",
      "nov_create",
      "dec_create",
    ];

    const array_name_edit = [
      "jan",
      "feb",
      "mar",
      "apr",
      "may",
      "jun",
      "jul",
      "aug",
      "sep",
      "oct",
      "nov",
      "dec",
    ];

    let name = any.target.name;
    let value = any.target.value;
    let data = {};
    data[name] = value;
    this.setState(data, () => {
      if (array_name_create.includes(name)) {
        this.handleKeyUp();
      }
      if (array_name_edit.includes(name)) {
        this.handleKeyUpEdit();
      }
      //to refresh state search when change filter type that not equal year
      if (filter_by == "year" && name != "year") {
        this.setState({
          search: "",
        });
      }
    });
  };
  //change state date
  handleDate = (name, date) => {
    this.setState({
      [name]: date,
    });
    this.setState({
      qty_create: null,
      jan_create: null,
      feb_create: null,
      mar_create: null,
      apr_create: null,
      may_create: null,
      jun_create: null,
      jul_create: null,
      aug_create: null,
      sep_create: null,
      oct_create: null,
      nov_create: null,
      dec_create: null,
    });
  };
  handleChange = (name, newValue) => {
    if (newValue) {
      this.setState(
        {
          [name]: newValue.label,
          [name + "_code"]: newValue.value,
        },
        () => {
          if (name == "year_filter") {
            this.handleSearch();
          }
        }
      );
      if (name == "material") {
        this.setState({
          isValidMaterial: true,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: true,
        });
      } else if (name == "material_create") {
        readMaterialAndEXPBySearch({
          is_uom: true,
          material_code: newValue?.value,
        }).then((response) => {
          this.refUnit.value = response.length > 0 ? response[0].value : null;
          this.setState({
            unit_create: response.length > 0 ? response[0].value : null,
            isValidMaterialCreate: true,
          });
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: true,
        });
      }
    } else {
      this.setState(
        {
          [name]: null,
          [name + "_code"]: null,
        },
        () => {
          if (name == "year_filter") {
            this.handleSearch();
          }
        }
      );
      if (name == "material") {
        this.setState({
          isValidMaterial: false,
        });
      } else if (name == "customer") {
        this.setState({
          isValidCustomer: false,
        });
      } else if (name == "material_create") {
        this.setState({
          isValidMaterialCreate: false,
        });
      } else if (name == "customer_create") {
        this.setState({
          isValidCustomerCreate: false,
        });
      }
    }
  };

  handleKeyUp = () => {
    let {
      jan_create,
      feb_create,
      mar_create,
      apr_create,
      may_create,
      jun_create,
      jul_create,
      aug_create,
      sep_create,
      oct_create,
      nov_create,
      dec_create,
    } = this.state;

    let qty_create = 0;
    if (!isNaN(parseFloat(jan_create)))
      qty_create += parseFloat(jan_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(feb_create)))
      qty_create += parseFloat(feb_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(mar_create)))
      qty_create += parseFloat(mar_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(apr_create)))
      qty_create += parseFloat(apr_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(may_create)))
      qty_create += parseFloat(may_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(jun_create)))
      qty_create += parseFloat(jun_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(jul_create)))
      qty_create += parseFloat(jul_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(aug_create)))
      qty_create += parseFloat(aug_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(sep_create)))
      qty_create += parseFloat(sep_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(oct_create)))
      qty_create += parseFloat(oct_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(nov_create)))
      qty_create += parseFloat(nov_create.replace(/,/g, ""));
    if (!isNaN(parseFloat(dec_create)))
      qty_create += parseFloat(dec_create.replace(/,/g, ""));
    this.setState({
      qty_create: thousandformat(qty_create, ".", ".", ","),
    });
  };

  handleKeyUpEdit = () => {
    let { jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec } =
      this.state;
    let qty = 0;
    if (!isNaN(parseFloat(jan)))
      qty += parseFloat(jan.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(feb)))
      qty += parseFloat(feb.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(mar)))
      qty += parseFloat(mar.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(apr)))
      qty += parseFloat(apr.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(may)))
      qty += parseFloat(may.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(jun)))
      qty += parseFloat(jun.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(jul)))
      qty += parseFloat(jul.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(aug)))
      qty += parseFloat(aug.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(sep)))
      qty += parseFloat(sep.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(oct)))
      qty += parseFloat(oct.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(nov)))
      qty += parseFloat(nov.toString().replace(/,/g, ""));
    if (!isNaN(parseFloat(dec)))
      qty += parseFloat(dec.toString().replace(/,/g, ""));
    this.setState(
      {
        qty: thousandformat(qty.toString(), ".", ".", ","),
      },
      () => {}
    );
  };

  //check diff date on day
  diff_date_on_day = (date1, date2) => {
    let diff_on_second = new Date(date2).getTime() - new Date(date1).getTime();
    return diff_on_second / (1000 * 60 * 60 * 24);
  };
  //date format on datatable
  GetDateFormat = (cell, row) => {
    const date_formatted = dateFormat(new Date(row.forecast_date), "yyyy");
    return <div>{date_formatted}</div>;
  };
  //action button
  GetActionFormat = (cell, row) => {
    const { isEdit, isDelete, editable } = this.state;
    const year_forecast = moment(row.forecast_date).format("YY");

    return (
      <div>
        {isEdit && (
          <button
            key={row._id}
            type="button"
            className="btn btn-info btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            onClick={() => {
              const {
                editable_date_start,
                editable_date_end,
                amount_backdate_month,
              } = this.state;

              //get backdate amount
              let backdate = amount_backdate_month ? amount_backdate_month : 0;

              //get date start, date end
              const date_start = moment(editable_date_start, "YYYY-MM-DD")
                .startOf("month")
                .format("YYYY-MM-DD");
              const date_end = moment(editable_date_end, "YYYY-MM-DD")
                .endOf("month")
                .format("YYYY-MM-DD");

              //copy array of editable, the keys only
              let temp_editable = { ...editable };
              let arr_editable = Object.keys(temp_editable);

              //check if today date is between date start and date end
              const isEditable = moment().isBetween(
                date_start,
                date_end,
                undefined,
                []
              );

              let arr_backdate = [];
              for (let i = 0; i < backdate; i++) {
                let year_backdate = moment()
                  .subtract(i + 1, "months")
                  .format("YY");
                if (year_backdate == year_forecast) {
                  arr_backdate.push(
                    moment()
                      .subtract(i + 1, "months")
                      .format("MMM-YY")
                  );
                }
              }

              arr_editable.forEach((month) => {
                let date = `${month}-${year_forecast}`;

                if (isEditable && arr_backdate.includes(date)) {
                  temp_editable[month] = true;
                } else {
                  temp_editable[month] = moment(date, "MMM-YY").isSameOrAfter(
                    moment().startOf("month")
                  );
                }
              });

              this.setState({
                editable: { ...temp_editable },
              });
              this.toDetail(
                row._id,
                row.segment_name,
                row.material_fg_name,
                row.material_fg_code,
                row.customer_name,
                row.customer_code,
                row.qty,
                row.unit,
                row.forecast_date,
                row.forecast.jan,
                row.forecast.feb,
                row.forecast.mar,
                row.forecast.apr,
                row.forecast.may,
                row.forecast.jun,
                row.forecast.jul,
                row.forecast.aug,
                row.forecast.sep,
                row.forecast.oct,
                row.forecast.nov,
                row.forecast.dec,
                row.category,
                row.shipped_to_name,
                row.shipped_to_code,
                row.categories ? row.categories.jan : null,
                row.categories ? row.categories.feb : null,
                row.categories ? row.categories.mar : null,
                row.categories ? row.categories.apr : null,
                row.categories ? row.categories.may : null,
                row.categories ? row.categories.jun : null,
                row.categories ? row.categories.jul : null,
                row.categories ? row.categories.aug : null,
                row.categories ? row.categories.sep : null,
                row.categories ? row.categories.oct : null,
                row.categories ? row.categories.nov : null,
                row.categories ? row.categories.dec : null
              );
            }}
          >
            <i className="fas fa-edit" key={row._id}></i>{" "}
          </button>
        )}

        {isDelete && (
          <button
            type="button"
            className="btn btn-danger btn-sm ml-2 mb-2 ts-buttom"
            size="sm"
            data-id={row._id}
            onClick={() => this.deleteData(row._id)}
          >
            <i className="fas fa-trash"></i>{" "}
          </button>
        )}
      </div>
    );
  };
  //thousand delimiter format
  GetThousandDelimiterFormat = (cell) => {
    return <div>{isNaN(cell) ? "" : thousandformat(cell, ".", ".", ",")}</div>;
  };

  deleteData = (id) => {
    const { history } = this.props;
    const { requester } = this.state;
    let collection = { _id: id, requester: requester };
    if (collection) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, cancel entries",
      }).then((result) => {
        if (result.value) {
          deleteSalesForecast(collection)
            .then(() => {
              toast.success("Data has been cancelled successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been cancelled successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.reloadDataBackend();
                history.push("/sales/sales-forecast");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-delete-salesforecast-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Information", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  toDetail = (
    id,
    segment,
    material,
    material_code,
    customer,
    customer_code,
    qty,
    unit,
    date,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
    category,
    shipped_to,
    shipped_to_code,
    cat_jan,
    cat_feb,
    cat_mar,
    cat_apr,
    cat_may,
    cat_jun,
    cat_jul,
    cat_aug,
    cat_sep,
    cat_oct,
    cat_nov,
    cat_dec
  ) => {
    this.setState(
      {
        id: id,
        segment: segment,
        material: material,
        material_code: material_code,
        customer: customer,
        customer_code: customer_code,
        qty: qty,
        unit: unit,
        date: date,
        jan: jan,
        feb: feb,
        mar: mar,
        apr: apr,
        may: may,
        jun: jun,
        jul: jul,
        aug: aug,
        sep: sep,
        oct: oct,
        nov: nov,
        dec: dec,
        submitted: false,
        category: category,
        shipped_to: shipped_to,
        shipped_to_code: shipped_to_code,
        cat_jan: cat_jan,
        cat_feb: cat_feb,
        cat_mar: cat_mar,
        cat_apr: cat_apr,
        cat_may: cat_may,
        cat_jun: cat_jun,
        cat_jul: cat_jul,
        cat_aug: cat_aug,
        cat_sep: cat_sep,
        cat_oct: cat_oct,
        cat_nov: cat_nov,
        cat_dec: cat_dec,
      },
      () => {
        this.toggle();
      }
    );
  };
  //toggle modal

  toggle = () => {
    this.setState({
      showModal: !this.state.showModal,
    });
  };
  //on modal hide

  onModalHide = () => {
    this.setState({
      id: null,
      submitted: false,
      currency: null,
      material: null,
      vendor: null,
      date: null,
      price: null,
      unit: null,
      showModal: !this.state.showModal,
      readOnly: false,
      category: null,
    });
  };
  //validate input
  validateForm = (
    qty,
    jan,
    feb,
    mar,
    apr,
    may,
    jun,
    jul,
    aug,
    sep,
    oct,
    nov,
    dec,
    cat_jan,
    cat_feb,
    cat_mar,
    cat_apr,
    cat_may,
    cat_jun,
    cat_jul,
    cat_aug,
    cat_sep,
    cat_oct,
    cat_nov,
    cat_dec
  ) => {
    let errors = this.state.errors;

    if (jan && !cat_jan) {
      errors.cat_jan = "This field is required";
    } else {
      delete errors.cat_jan;
    }
    if (feb && !cat_feb) {
      errors.cat_feb = "This field is required";
    } else {
      delete errors.cat_feb;
    }
    if (mar && !cat_mar) {
      errors.cat_mar = "This field is required";
    } else {
      delete errors.cat_mar;
    }

    if (apr && !cat_apr) {
      errors.cat_apr = "This field is required";
    } else {
      delete errors.cat_apr;
    }

    if (may && !cat_may) {
      errors.cat_may = "This field is required";
    } else {
      delete errors.cat_may;
    }

    if (jun && !cat_jun) {
      errors.cat_jun = "This field is required";
    } else {
      delete errors.cat_jun;
    }

    if (jul && !cat_jul) {
      errors.cat_jul = "This field is required";
    } else {
      delete errors.cat_jul;
    }

    if (aug && !cat_aug) {
      errors.cat_aug = "This field is required";
    } else {
      delete errors.cat_aug;
    }

    if (sep && !cat_sep) {
      errors.cat_sep = "This field is required";
    } else {
      delete errors.cat_sep;
    }

    if (oct && !cat_oct) {
      errors.cat_oct = "This field is required";
    } else {
      delete errors.cat_oct;
    }

    if (nov && !cat_nov) {
      errors.cat_nov = "This field is required";
    } else {
      delete errors.cat_nov;
    }

    if (dec && !cat_dec) {
      errors.cat_dec = "This field is required";
    } else {
      delete errors.cat_dec;
    }

    return errors;
  };
  validateFormCreate = (
    material,
    customer,
    shipped_to,
    jan_create,
    feb_create,
    mar_create,
    apr_create,
    may_create,
    jun_create,
    jul_create,
    aug_create,
    sep_create,
    oct_create,
    nov_create,
    dec_create,
    cat_jan_create,
    cat_feb_create,
    cat_mar_create,
    cat_apr_create,
    cat_may_create,
    cat_jun_create,
    cat_jul_create,
    cat_aug_create,
    cat_sep_create,
    cat_oct_create,
    cat_nov_create,
    cat_dec_create
  ) => {
    let errors = this.state.errors_create;
    if (!material) {
      errors.material = "This is field required";
      this.setState({
        isValidMaterialCreate: false,
      });
    } else {
      delete errors.material;
    }
    if (!customer) {
      errors.customer = "This is field required";
      this.setState({
        isValidCustomerCreate: false,
      });
    } else {
      delete errors.customer;
    }

    if (jan_create && !cat_jan_create) {
      errors.cat_jan_create = "This field is required";
    } else {
      delete errors.cat_jan_create;
    }
    if (feb_create && !cat_feb_create) {
      errors.cat_feb_create = "This field is required";
    } else {
      delete errors.cat_feb_create;
    }
    if (mar_create && !cat_mar_create) {
      errors.cat_mar_create = "This field is required";
    } else {
      delete errors.cat_mar_create;
    }

    if (apr_create && !cat_apr_create) {
      errors.cat_apr_create = "This field is required";
    } else {
      delete errors.cat_apr_create;
    }

    if (may_create && !cat_may_create) {
      errors.cat_may_create = "This field is required";
    } else {
      delete errors.cat_may_create;
    }

    if (jun_create && !cat_jun_create) {
      errors.cat_jun_create = "This field is required";
    } else {
      delete errors.cat_jun_create;
    }

    if (jul_create && !cat_jul_create) {
      errors.cat_jul_create = "This field is required";
    } else {
      delete errors.cat_jul_create;
    }

    if (aug_create && !cat_aug_create) {
      errors.cat_aug_create = "This field is required";
    } else {
      delete errors.cat_aug_create;
    }

    if (sep_create && !cat_sep_create) {
      errors.cat_sep_create = "This field is required";
    } else {
      delete errors.cat_sep_create;
    }

    if (oct_create && !cat_oct_create) {
      errors.cat_oct_create = "This field is required";
    } else {
      delete errors.cat_oct_create;
    }

    if (nov_create && !cat_nov_create) {
      errors.cat_nov_create = "This field is required";
    } else {
      delete errors.cat_nov_create;
    }

    if (dec_create && !cat_dec_create) {
      errors.cat_dec_create = "This field is required";
    } else {
      delete errors.cat_dec_create;
    }

    return errors;
  };

  validateSearch = (filter_by, search) => {
    let errors = this.state.errors_search;
    if (!filter_by) {
      errors.filter_by = "This is field required";
    }
    if (!search) {
      errors.search = "This is field required";
    }

    return errors;
  };
  //get data from backend
  reloadDataBackend = () => {
    const {
      sortBy,
      sortOrder,
      page,
      sizePerPage,
      search,
      filter_by,
      year_filter,
    } = this.state;
    const query_string = `sortBy=${sortBy}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&search=${search}&filter_by=${filter_by}${
      year_filter ? "&year=" + year_filter : ""
    }`;
    this.form.reset();

    if (this.refMaterial.state.value) {
      this.refMaterial.state.value.value = "";
      this.refMaterial.state.value.label = "";
    }
    if (this.refCustomer.state.value) {
      this.refCustomer.state.value.value = "";
      this.refCustomer.state.value.label = "";
    }
    if (this.refShippedTo.state.value) {
      this.refShippedTo.state.value.value = "";
      this.refShippedTo.state.value.label = "";
    }
    this.setState({
      shipped_to_create: null,
      shipped_to_create_code: null,
    });

    readSalesForecas(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
        submitted_create: false,
        qty_create: null,
        unit_create: null,
        material_create: null,
        material_create_code: null,
        customer_create: null,
        customer_create_code: null,
        jan_create: "",
        feb_create: "",
        mar_create: "",
        apr_create: "",
        may_create: "",
        jun_create: "",
        jul_create: "",
        aug_create: "",
        sep_create: "",
        oct_create: "",
        nov_create: "",
        dec_create: "",
        category_create: null,
        editable: {
          Jan: false,
          Feb: false,
          Mar: false,
          Apr: false,
          May: false,
          Jun: false,
          Jul: false,
          Aug: false,
          Sep: false,
          Oct: false,
          Nov: false,
          Dec: false,
        },
      });
    });

    readMaterialAndEXPBySearch({
      sizePerPage: 100,
      constraint_value: "ZFGD",
      constraint: "material_type",
    }).then((response) => {
      this.setState({ select_items_material: response });
    });

    readSegment().then((response) => {
      this.setState({ select_items_segemnt: response });
    });

    readCustomerAsync({
      sizePerPage: 100,
    }).then((response) => {
      this.setState({ select_items_customer: response });
    });

    readSalesForecas(`forecast_year=true`).then((response) => {
      this.setState({
        forecast_year: response,
      });
    });
  };

  handleTableChange = (type, { sortField, sortOrder, page, sizePerPage }) => {
    if (page == 0) {
      page = 1;
    }
    const { search, filter_by, year_filter } = this.state;
    this.setState({
      sizePerPage: sizePerPage,
      page: page,
      sortBy: sortField,
      sortOrder: sortOrder,
    });

    const query_string = `sortBy=${sortField}&sortOrder=${sortOrder}&page=${page}&sizePerPage=${sizePerPage}&search=${search}&filter_by=${filter_by}${
      year_filter ? "&year=" + year_filter : ""
    }`;

    readSalesForecas(query_string).then((response) => {
      this.setState({
        list_datatable: response ? response.foundData : [],
        totalSize: response.countData ? response.countData : 0,
        page: response.currentPage ? response.currentPage : 1,
      });
    });
  };
  handleNotification = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        let payload = {
          requester: this.state.requester,
          requester_name: this.state.requester_name,
          page: "sales_forecast",
        };
        sendNotification(payload)
          .then(() => {
            toast.success("Notification has been sent!", {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-success",
            });
            Swal.fire({
              title: "Information",
              icon: "success",
              text: "Notification has been sent",
              showConfirmButton: false,
              timer: 2000,
            });
            this.setState({
              disableButtonNotification: true,
            });
            this.myInterval = setInterval(() => {
              const { seconds } = this.state;
              if (seconds > 1) {
                this.setState(({ seconds }) => ({
                  seconds: seconds - 1,
                }));
              } else {
                this.setState({
                  disableButtonNotification: false,
                  seconds: 60,
                });
                clearInterval(this.myInterval);
              }
            }, 1000);
          })
          .catch((err) => {
            toast.error(err, {
              position: "top-right",
              autoClose: 1500,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              toastId: "customId-update-daily-spot-price-failed",
            });
            this.setState({
              loading: false,
            });
          });
      }
    });
  };
  handleSubmit = () => {
    this.setState({ submitted_create: true });
    const { history } = this.props;
    const {
      requester,
      date_create,
      segment_create,
      material_create,
      material_create_code,
      customer_create,
      customer_create_code,
      shipped_to_create,
      shipped_to_create_code,
      unit_create,
      qty_create,
      jan_create,
      feb_create,
      mar_create,
      apr_create,
      may_create,
      jun_create,
      jul_create,
      aug_create,
      sep_create,
      oct_create,
      nov_create,
      dec_create,
      cat_jan_create,
      cat_feb_create,
      cat_mar_create,
      cat_apr_create,
      cat_may_create,
      cat_jun_create,
      cat_jul_create,
      cat_aug_create,
      cat_sep_create,
      cat_oct_create,
      cat_nov_create,
      cat_dec_create,
      errors_create,
    } = this.state;

    if (
      !this.validateFormCreate(
        material_create,
        customer_create,
        shipped_to_create,
        jan_create,
        feb_create,
        mar_create,
        apr_create,
        may_create,
        jun_create,
        jul_create,
        aug_create,
        sep_create,
        oct_create,
        nov_create,
        dec_create,
        cat_jan_create,
        cat_feb_create,
        cat_mar_create,
        cat_apr_create,
        cat_may_create,
        cat_jun_create,
        cat_jul_create,
        cat_aug_create,
        cat_sep_create,
        cat_oct_create,
        cat_nov_create,
        cat_dec_create
      )
    ) {
      return;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    let date_formatted = dateFormat(new Date(date_create), "yyyy-01-01");
    let collection = {
      forecast_date: date_formatted,
      requester: requester,
      segment_name: segment_create,
      segment_code: segment_create,
      material_fg_name: material_create,
      material_fg_code: material_create_code,
      qty: qty_create,
      customer_name: customer_create,
      customer_code: customer_create_code,
      shipped_to_name: shipped_to_create,
      shipped_to_code: shipped_to_create_code,
      unit: unit_create,
      department: department,
      forecast: {
        jan: jan_create ? jan_create.replace(/,/g, "") : "",
        feb: feb_create ? feb_create.replace(/,/g, "") : "",
        mar: mar_create ? mar_create.replace(/,/g, "") : "",
        apr: apr_create ? apr_create.replace(/,/g, "") : "",
        may: may_create ? may_create.replace(/,/g, "") : "",
        jun: jun_create ? jun_create.replace(/,/g, "") : "",
        jul: jul_create ? jul_create.replace(/,/g, "") : "",
        aug: aug_create ? aug_create.replace(/,/g, "") : "",
        sep: sep_create ? sep_create.replace(/,/g, "") : "",
        oct: oct_create ? oct_create.replace(/,/g, "") : "",
        nov: nov_create ? nov_create.replace(/,/g, "") : "",
        dec: dec_create ? dec_create.replace(/,/g, "") : "",
      },
      categories: {
        jan: cat_jan_create,
        feb: cat_feb_create,
        mar: cat_mar_create,
        apr: cat_apr_create,
        may: cat_may_create,
        jun: cat_jun_create,
        jul: cat_jul_create,
        aug: cat_aug_create,
        sep: cat_sep_create,
        oct: cat_oct_create,
        nov: cat_nov_create,
        dec: cat_dec_create,
      },
    };

    if (
      requester &&
      material_create &&
      customer_create &&
      segment_create &&
      unit_create &&
      Object.values(errors_create).length == 0
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.value) {
          addSalesForecas(collection)
            .then(() => {
              toast.success("Data has been saved successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been saved successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                //read data exchange rate on backend
                this.reloadDataBackend();
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-add-daily-spot-price-failed",
              });
              if (
                err ===
                "data is already exist, Are you sure to overwrite data ?"
              ) {
                Swal.fire({
                  title: "Data is already exist",
                  text: `Are you sure to overwrite data ?`,
                  icon: "info",
                  showCancelButton: true,
                  confirmButtonText: "Yes",
                }).then((result) => {
                  if (result.value) {
                    overwriteSalesForecast(collection)
                      .then(() => {
                        toast.success("Data has been updated successfully", {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-success",
                        });
                        Swal.fire({
                          title: "Information",
                          icon: "success",
                          text: "Data has been updated successfully",
                          showConfirmButton: false,
                          timer: 2000,
                        }).then(() => {
                          //read data exchange rate on backend
                          this.reloadDataBackend();
                          history.push("/sales/sales-forecast");
                        });
                      })
                      .catch((err) => {
                        toast.error(err, {
                          position: "top-right",
                          autoClose: 1500,
                          hideProgressBar: true,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          toastId: "customId-update-daily-spot-price-failed",
                        });
                        this.setState({
                          loading: false,
                        });
                      });
                  } else if (result.dismiss === Swal.DismissReason.cancel) {
                    Swal.fire("Cancelled", "Your data is safe :)", "error");
                  }
                });
              }
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle submit edit
  handleSubmitEdit = () => {
    this.setState({ submitted: true });

    //get department
    const user = JSON.parse(localStorage.getItem("user"));
    let department = user.details.hris_org_tree.current_person.nama_department;

    const {
      id,
      qty,
      requester,
      date,
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec,
      cat_jan,
      cat_feb,
      cat_mar,
      cat_apr,
      cat_may,
      cat_jun,
      cat_jul,
      cat_aug,
      cat_sep,
      cat_oct,
      cat_nov,
      cat_dec,
      errors,
    } = this.state;
    if (
      !this.validateForm(
        qty,
        jan,
        feb,
        mar,
        apr,
        may,
        jun,
        jul,
        aug,
        sep,
        oct,
        nov,
        dec,
        cat_jan,
        cat_feb,
        cat_mar,
        cat_apr,
        cat_may,
        cat_jun,
        cat_jul,
        cat_aug,
        cat_sep,
        cat_oct,
        cat_nov,
        cat_dec
      )
    ) {
      return;
    }
    const { history } = this.props;
    let collection = {
      _id: id,
      qty: qty ? qty.toString() : "0",
      requester: requester,
      department: department,
      spot_date: date,
      forecast: {
        jan: jan ? jan.toString().replace(/,/g, "") : "",
        feb: feb ? feb.toString().replace(/,/g, "") : "",
        mar: mar ? mar.toString().replace(/,/g, "") : "",
        apr: apr ? apr.toString().replace(/,/g, "") : "",
        may: may ? may.toString().replace(/,/g, "") : "",
        jun: jun ? jun.toString().replace(/,/g, "") : "",
        jul: jul ? jul.toString().replace(/,/g, "") : "",
        aug: aug ? aug.toString().replace(/,/g, "") : "",
        sep: sep ? sep.toString().replace(/,/g, "") : "",
        oct: oct ? oct.toString().replace(/,/g, "") : "",
        nov: nov ? nov.toString().replace(/,/g, "") : "",
        dec: dec ? dec.toString().replace(/,/g, "") : "",
      },
      categories: {
        jan: cat_jan,
        feb: cat_feb,
        mar: cat_mar,
        apr: cat_apr,
        may: cat_may,
        jun: cat_jun,
        jul: cat_jul,
        aug: cat_aug,
        sep: cat_sep,
        oct: cat_oct,
        nov: cat_nov,
        dec: cat_dec,
      },
    };
    if (id && Object.values(errors).length == 0) {
      Swal.fire({
        title: "Are you sure to update?",
        text: "Please check your entries",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes, update entries",
      }).then((result) => {
        if (result.value) {
          editSalesForecast(collection)
            .then(() => {
              this.toggle();
              this.setState({
                submitted: false,
              });
              toast.success("Data has been updated successfully", {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-success",
              });
              Swal.fire({
                title: "Information",
                icon: "success",
                text: "Data has been updated successfully",
                showConfirmButton: false,
                timer: 2000,
              }).then(() => {
                this.reloadDataBackend();
                history.push("/sales/sales-forecast");
              });
            })
            .catch((err) => {
              toast.error(err, {
                position: "top-right",
                autoClose: 1500,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                toastId: "customId-update-daily-spot-price-failed",
              });
              this.setState({
                loading: false,
              });
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };
  //handle search
  handleSearch = () => {
    const { search, sizePerPage, filter_by, sortBy, sortOrder, year_filter } =
      this.state;
    let new_page = 1;
    const query_string = `page=${new_page}&sizePerPage=${sizePerPage}&search=${search}&filter_by=${filter_by}&sortBy=${sortBy}&sortOrder=${sortOrder}${
      year_filter ? "&year=" + year_filter : ""
    }`;
    readSalesForecas(query_string).then((response) => {
      this.setState({
        list_datatable: response.foundData ? response.foundData : [],
        totalSize: response.foundData ? response.countData : 0,
        page: response.foundData ? response.currentPage : 1,
      });
    });
  };

  //handle download
  handleDownload = () => {
    this.setState({ loading_download_history: true });
    const {
      search,
      sizePerPage,
      filter_by,
      sortBy,
      sortOrder,
      year_filter,
      month_start,
      month_end,
    } = this.state;

    const query = {
      date_start: moment(month_start).format("YYYY-MM-DD"),
      date_end: moment(month_end).format("YYYY-MM-DD"),
      sizePerPage: sizePerPage,
      search: search,
      filter_by: filter_by,
      sortBy: sortBy,
      sortOrder: sortOrder,
      year_filter: year_filter ?? "",
    };

    downloadExcelSalesForecast(query)
      .then((response) => {
        const datetime = moment().format("YYYYMMDD-HHmmss");
        const fileName = `download-history-sales-forecast-${datetime}.xlsx`;
        FileDownload(response, fileName);
        this.handleBulkClear();
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loading_download_history: false });
      });
  };

  validateBulkForm = (values) => {
    const new_errors = {};

    if (values.excel.length === 0) {
      new_errors.excel = "Please select the excel file";
    }
    if (!values.template) {
      new_errors.template = "Please select the template";
    }

    this.setState({ errors_create_bulk: new_errors });

    return { error: Object.keys(new_errors).length !== 0, message: new_errors };
  };

  handleBulkGenerate = () => {
    try {
      const { month_start, month_end } = this.state;
      this.setState({ loading_download: true });

      const query = {
        date_start: moment(month_start).format("YYYY-MM-DD"),
        date_end: moment(month_end).format("YYYY-MM-DD"),
      };
      generateExcelSalesForecastConfirmation(query)
        .then((response) => {
          const datetime = moment().format("YYYYMMDD-HHmmss");
          const fileName = `master-sales-forecast-${datetime}.xlsx`;
          FileDownload(response, fileName);
          this.handleBulkClear();
        })
        .catch(() => {})
        .finally(() => {
          this.setState({ loading_download: false });
        });
    } catch (e) {}
  };

  handleBulkSubmit = () => {
    try {
      const {
        month_start,
        month_end,
        file_worksheet,
        requester,
        department,
        template,
      } = this.state;
      const { history } = this.props;
      const new_values = {
        date_start: moment(month_start).format("YYYY-MM-DD"),
        date_end: moment(month_end).format("YYYY-MM-DD"),
        excel: file_worksheet,
        department,
        requester,
        template,
      };

      const validate = this.validateBulkForm(new_values);
      if (validate.error) {
        return;
      }

      Swal.fire({
        title: "Are you sure?",
        text: "Please check your entries !",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.setState({ loading: true });
          createBulkSalesForecastConfirmation(new_values)
            .then((res) => {
              this.setState({
                loading: false,
                showModalConfirm: true,
                confirm_data: {
                  found: res.data.found,
                  not_found: res.data.not_found,
                },
                template: null,
              });
            })
            .catch((err) => {
              this.setState({ loading: false });
              Swal.fire("Information", err, "error");
            });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your data is safe :)", "error");
        }
      });
    } catch (err) {
      Swal.fire("Information ", "Please check your entries again!", "error");
    } finally {
    }
  };

  handleConfirmUpload = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Please check your entries !",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        this.setState({ loading: true });
        const { confirm_data, file_object, requester, requester_name } =
          this.state;
        confirm_data.created_by = {
          username: requester,
          name: requester_name,
        };
        const { history } = this.props;

        const formData = new FormData();
        formData.append("data", JSON.stringify(confirm_data));
        formData.append("upload_file", file_object);

        confirmBulk(formData)
          .then(() => {
            Swal.fire(
              "Information",
              "Data has been imported successfuly!",
              "success"
            );
            // CLEAR
            this.handleBulkClear();
            //
            this.reloadDataBackend();
            this.setState({ showModalConfirm: false });
            history.push("/sales/sales-forecast");
          })
          .catch((err) => {
            Swal.fire("Information", err?.message, "error");
          })
          .finally(() => {
            this.setState({ loading: false });
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  };

  handleBulkClear = () => {
    this.setState({
      load_file: false,
      file_value: null,
      file_object: null,
 inputFile:      /* eslint-disable-next-line no-restricted-globals */
        self.crypto.getRandomValues(array),
      file_worksheet: [],
      file: {
        name: "Choose file",
        type: "",
        size: 0,
      },
      month_start: moment(new Date()).startOf("years").toDate(),
      month_end: moment(new Date()).endOf("years").toDate(),
      errors_create_bulk: {},
    });
  };

  handleBulkFile = (e) => {
    if (e.target.files.length > 0) {
      const fileObject = e.target.files[0];

      this.handleReadFile(fileObject)
        .then((response) => {
          this.setState({
            load_file: true,
            file_object: fileObject,
            file_value: e.target.value,
            file_worksheet: response,
            file: {
              name: fileObject.name,
              type: fileObject.type,
              size: fileObject.size,
            },
          });
        })
        .catch((e) => {
          this.setState({
            load_file: false,
            file_value: null,
            file_object: null,
            file_worksheet: [],
            file: {
              name: "Choose file",
              type: "",
              size: 0,
            },
          });
        })
        .finally(() => {
          this.setState({ errors_create_bulk: {} });
        });
    }
  };

  handleReadFile = (file) => {
    return new Promise((resolve, reject) => {
      try {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e) => {
          const binaryString = e.target.result;
          const workbook = XLSX.read(binaryString, { type: "buffer" });

          const result = workbook.SheetNames.map((item) => {
            const worksheet = workbook.Sheets[item];
            const gg = XLSX.utils.sheet_to_json(worksheet, {
              header: 1,
              defval: "",
              blankrows: false,
            });
            return {
              sheetName: item,
              worksheet: XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                defval: "",
                blankrows: false,
              }),
            };
          });
          resolve(result);
        };
      } catch (e) {
        reject(new Error(e.message));
      }
    });
  };

  handleBulkYear = (e) => {
    const start = moment(e).startOf("years").toDate();
    const end = moment(e).endOf("years").toDate();
    this.setState({ month_start: start, month_end: end });
  };

  hideModalConfirm = () => {
    this.setState({ showModalConfirm: false });
  };

  onModalLoadingHide = () => {
    this.setState({ loading: false });
  };

  getCustomer = (input) => {
    return readCustomerAsync({
      search: input,
      sizePerPage: 100,
    }).then((response) => {
      return response;
    });
  };

  getMaterial = (input) => {
    return readMaterialAndEXPBySearch({
      search: input,
      sizePerPage: 100,
      constraint_value: "ZFGD",
      constraint: "material_type",
    }).then((response) => {
      return response;
    });
  };
  render() {
    let {
      inputFile,
      loading_download_history,
      loading_download,
      disableButtonNotification,
      seconds,
      columns,
      defaultSorted,
      showModal,
      cellEditProps,
      select_items_material,
      select_items_uom,
      select_items_segemnt,
      select_items_customer,
      list_datatable,
      id,
      material,
      customer,
      shipped_to,
      qty,
      material_create,
      customer_create,
      shipped_to_create,
      qty_create,
      unit_create,
      unit,
      segment_create,
      segment,
      date_create,
      date,
      errors,
      errors_create,
      submitted_create,
      submitted,
      unitreadOnly,
      loading,
      isValidMaterialCreate,
      isValidCustomerCreate,
      isValidShippedToCreate,
      isClearable,
      isCreate,
      isRead,
      search,
      page,
      sizePerPage,
      totalSize,
      jan_create,
      feb_create,
      mar_create,
      apr_create,
      may_create,
      jun_create,
      jul_create,
      aug_create,
      sep_create,
      oct_create,
      nov_create,
      dec_create,
      jan,
      feb,
      mar,
      apr,
      may,
      jun,
      jul,
      aug,
      sep,
      oct,
      nov,
      dec,
      select_items_category,
      filter_by,
      year_filter,
      forecast_year,
      isLoading,
      cat_jan_create,
      cat_feb_create,
      cat_mar_create,
      cat_apr_create,
      cat_may_create,
      cat_jun_create,
      cat_jul_create,
      cat_aug_create,
      cat_sep_create,
      cat_oct_create,
      cat_nov_create,
      cat_dec_create,
      cat_jan,
      cat_feb,
      cat_mar,
      cat_apr,
      cat_may,
      cat_jun,
      cat_jul,
      cat_aug,
      cat_sep,
      cat_oct,
      cat_nov,
      cat_dec,
      editable,
      file,
      file_value,
      file_worksheet,
      load_file,
      month_start,
      errors_create_bulk,
      showModalConfirm,
      confirm_data,
      template,
    } = this.state;
    const customStylesMaterialCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidMaterialCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidMaterialCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesCustomerCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidCustomerCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidCustomerCreate
            ? "#ddd"
            : "green",
        },
      }),
    };
    const customStylesShippedToCreate = {
      control: (base, state) => ({
        ...base,
        borderColor: state.isFocused
          ? "#ddd"
          : isValidShippedToCreate
          ? "#ddd"
          : "red",
        // overwrittes hover style
        "&:hover": {
          borderColor: state.isFocused
            ? "#ddd"
            : isValidShippedToCreate
            ? "#ddd"
            : "green",
        },
      }),
    };

    const customStyles = {
      container: (provided, { selectProps: { fontSize } }) => ({
        ...provided,
        padding: "0",
        border: "0",
        fontSize: fontSize ? fontSize : "0.8rem",
        zIndex: "5",
      }),
      menu: (provided, { selectProps: { zIndex } }) => ({
        ...provided,
        zIndex: zIndex ? zIndex : null,
      }),
      option: (provided, { selectProps: { fontSize } }) => ({
        ...provided,
        fontSize: fontSize ? fontSize : "0.8rem",
      }),
      control: (
        provided,
        { selectProps: { isValid, isFocused, fontSize } }
      ) => ({
        ...provided,
        height: "calc(1.5em + 0.5rem + 4px)",
        minHeight: "calc(1.5em + 0.5rem + 4px)",
        fontSize: fontSize ? fontSize : "0.8rem",
        boxShadow: "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
        transition:
          "background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
        ":hover": {
          borderColor: isFocused ? "#ddd" : isValid ? "#ddd" : "green",
          boxShadow: isFocused
            ? "inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)"
            : "inset 0 1px 1px rgba(0, 0, 0, 0.075)",
        },
      }),
      valueContainer: (provided, { selectProps: { zIndex, fontSize } }) => ({
        ...provided,
        zIndex: zIndex ? zIndex : null,
        fontSize: fontSize ? fontSize : "0.8",

        marginTop: "0",
        marginLeft: "6px",
        padding: "0",
        border: "0",
      }),
      dropdownIndicator: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0",
        border: "0",
        width: "16px",
      }),
      clearIndicator: (provided) => ({
        ...provided,
        marginTop: "0",
        padding: "0",
        border: "0",
        width: "16px",
      }),
      indicatorsContainer: (provided) => ({
        ...provided,
        paddingRight: "4px",
        border: "0",
      }),
    };

    const headerStyleBlue = { backgroundColor: "#17A2B8", color: "white" };

    const headerStyleYellow = { backgroundColor: "yellow", color: "black" };

    return (
      <div className="sidebar-mini sidebar-collapse text-sm">
        <div className="wrapper">
          <ToastContainer />
          <Modal
            show={loading}
            size="xl"
            backdrop="static"
            onHide={this.onModalLoadingHide}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            keyboard={false}
          >
            <ModalHeader className="bg-info text-white"></ModalHeader>
            <ModalBody>
              <Container>
                <Row>
                  <Col xs={12} md={12}>
                    <ProgressBar animated now={100} />
                  </Col>
                </Row>
              </Container>
            </ModalBody>
          </Modal>

          <Modal
            show={showModalConfirm}
            size="xl"
            backdrop="static"
            onHide={this.hideModalConfirm}
            scrollable={true}
          >
            <ModalHeader closeButton className="bg-info text-white">
              <ModalTitle>Confirm Data Upload</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <Container>
                <div>Data ready to insert</div>
                <table className="table table-bordered">
                  <thead>
                    <tr style={headerStyleBlue}>
                      <th width="30%">Customer</th>
                      <th width="30%">Material</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {confirm_data.found && confirm_data.found.length > 0 ? (
                      confirm_data.found.map((item) => {
                        return (
                          <tr>
                            <td>{item.customer_name}</td>
                            <td>{item.material_fg_name}</td>
                            <td>{item.remark}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div>Data not found</div>
                <table className="table table-bordered">
                  <thead>
                    <tr style={headerStyleYellow}>
                      <th width="30%">Customer</th>
                      <th width="30%">Material</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    {confirm_data.not_found &&
                    confirm_data.not_found.length > 0 ? (
                      confirm_data.not_found.map((item) => {
                        return (
                          <tr>
                            <td>{item.customer_name}</td>
                            <td>{item.material_fg_name}</td>
                            <td>{item.remark}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>Data Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Container>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="danger"
                className="float-right"
                onClick={this.hideModalConfirm}
              >
                <i className="fas fa-times" /> Cancel
              </Button>
              <Button
                variant="success"
                className="float-right"
                onClick={this.handleConfirmUpload}
              >
                <i className="fas fa-check" /> Confirm
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            show={showModal}
            size="xl"
            backdrop="static"
            onHide={this.onModalHide}
          >
            <form
              className={
                submitted
                  ? "needs-validation was-validated"
                  : "needs-validation"
              }
              noValidate
            >
              <ModalHeader closeButton className="bg-info text-white">
                <ModalTitle>{id ? "Update" : ""} Sales Forecast</ModalTitle>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors_create.segment != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">
                          Segment <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          name="segment"
                          defaultValue={segment}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />

                        {errors_create.segment && (
                          <div className="invalid-feedback">
                            {errors_create.segment}
                          </div>
                        )}
                      </div>

                      <div className="form-group">
                        <label htmlFor="date">
                          Year <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="form-control form-control-sm"
                          name="date"
                          defaultValue={dateFormat(date, "yyyy")}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                        {errors_create.date && (
                          <div className="invalid-feedback">
                            {errors_create.date}
                          </div>
                        )}
                      </div>

                      <div>
                        <label htmlFor="price">
                          Qty <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          step="1"
                          className="form-control form-control-sm"
                          name="qty"
                          placeholder="Qty"
                          required="required"
                          value={qty}
                          onChange={this.onChangeValue}
                          readOnly={true}
                          min="0"
                        />
                      </div>
                      <br></br>
                    </Col>

                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors_create.customer != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="customer">
                          Sold To <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="form-control form-control-sm"
                          name="customer"
                          defaultValue={customer}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                      <div
                        className={
                          errors_create.shipped_to != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="shipped_to">Shipped To</label>
                        <input
                          className="form-control form-control-sm"
                          name="shipped_to"
                          defaultValue={shipped_to}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                    </Col>
                    <Col xs={12} md={4}>
                      <div
                        className={
                          errors_create.material != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="material">
                          Material FG <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="form-control form-control-sm"
                          name="material"
                          defaultValue={material}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />
                      </div>
                      <div
                        className={
                          errors_create.unit != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                      >
                        <label htmlFor="currency">
                          Unit <span style={{ color: "red" }}>*</span>
                        </label>

                        <input
                          className="form-control form-control-sm"
                          name="unit"
                          defaultValue={unit}
                          onChange={this.onChangeValue}
                          readOnly={true}
                        />

                        {errors_create.unit && (
                          <div className="invalid-feedback">
                            {errors_create.unit}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>

                  <hr></hr>
                  <div className="d-flex flex-row justify-content-between">
                    <div className="d-flex flex-column">
                      <label htmlFor="quantity">Month</label>
                      <label htmlFor="quantity">Quantity</label>
                      <label htmlFor="category" className="mb-3">
                        Category
                      </label>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Jan-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="jan"
                          value={jan || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Jan}
                        />
                        {errors.jan && (
                          <div className="invalid-feedback">{errors.jan}</div>
                        )}
                      </div>
                      <div
                        className={
                          !errors.cat_jan
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            !errors.cat_jan
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_jan"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_jan}
                          required={errors.cat_jan}
                          disabled={!editable.Jan}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_jan && (
                          <div className="invalid-feedback">
                            {errors.cat_jan}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Feb-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="any"
                          className="form-control form-control-sm"
                          name="feb"
                          value={feb || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Feb}
                        />
                        {errors.feb && (
                          <div className="invalid-feedback">{errors.feb}</div>
                        )}
                      </div>
                      <div
                        className={
                          !errors.cat_feb
                            ? "form-group "
                            : "form-group has-error has-feedback"
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            !errors.cat_feb
                              ? "form-control form-control-sm "
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_feb"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_feb}
                          required={errors.cat_feb}
                          disabled={!editable.Feb}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_feb && (
                          <div className="invalid-feedback">
                            {errors.cat_feb}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div style={{ width: "90%" }} className="text-center">
                        <label htmlFor="price">
                          Mar-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="mar"
                          value={mar || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Mar}
                        />
                        {errors.mar && (
                          <div className="invalid-feedback">{errors.mar}</div>
                        )}
                      </div>
                      <div
                        className={
                          !errors.cat_mar
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            !errors.cat_mar
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_mar"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_mar}
                          required={errors.cat_mar}
                          disabled={!editable.Mar}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_mar && (
                          <div className="invalid-feedback">
                            {errors.cat_mar}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div style={{ width: "90%" }} className=" text-center">
                        <label htmlFor="price">
                          Apr-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="apr"
                          value={apr || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Apr}
                        />
                        {errors.apr && (
                          <div className="invalid-feedback">{errors.apr}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_apr != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_apr != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_apr"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_apr}
                          required={errors.cat_apr}
                          disabled={!editable.Apr}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_apr && (
                          <div className="invalid-feedback">
                            {errors.cat_apr}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          May-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="may"
                          value={may || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.May}
                        />
                        {errors.may && (
                          <div className="invalid-feedback">{errors.may}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_may != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_may != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_may"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_may}
                          required={errors.cat_may}
                          disabled={!editable.May}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_may && (
                          <div className="invalid-feedback">
                            {errors.cat_may}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Jun-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="jun"
                          value={jun || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Jun}
                        />
                        {errors.jun && (
                          <div className="invalid-feedback">{errors.jun}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_jun != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback"
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_jun != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_jun"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_jun}
                          required={errors.cat_jun}
                          disabled={!editable.Jun}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_jun && (
                          <div className="invalid-feedback">
                            {errors.cat_jun}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Jul-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="jul"
                          value={jul || ""}
                          onChange={this.onChangeValue}
                          min="0"
                          readOnly={!editable.Jul}
                        />
                        {errors.jul && (
                          <div className="invalid-feedback">{errors.jul}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_jul != "This field is required"
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_jul != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_jul"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_jul}
                          required={errors.cat_jul}
                          disabled={!editable.Jul}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_jul && (
                          <div className="invalid-feedback">
                            {errors.cat_jul}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className=" text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Aug-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="aug"
                          value={aug || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Aug}
                        />
                        {errors.aug && (
                          <div className="invalid-feedback">{errors.aug}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_aug != "This field is required"
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_aug != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_aug"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_aug}
                          required={errors.cat_aug}
                          disabled={!editable.Aug}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_aug && (
                          <div className="invalid-feedback">
                            {errors.cat_aug}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className=" text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Sep-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="sep"
                          value={sep || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Sep}
                        />
                        {errors.sep && (
                          <div className="invalid-feedback">{errors.sep}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_sep != "This field is required"
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_sep != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_sep"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_sep}
                          required={errors.cat_sep}
                          disabled={!editable.Sep}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_sep && (
                          <div className="invalid-feedback">
                            {errors.cat_sep}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className="text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Oct-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="oct"
                          value={oct || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Oct}
                        />
                        {errors.oct && (
                          <div className="invalid-feedback">{errors.oct}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_oct != "This field is required"
                            ? "form-group"
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_oct != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_oct"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_oct}
                          required={errors.cat_oct}
                          disabled={!editable.Oct}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_oct && (
                          <div className="invalid-feedback">
                            {errors.cat_oct}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className=" text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Nov-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="nov"
                          value={nov || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Nov}
                        />
                        {errors.nov && (
                          <div className="invalid-feedback">{errors.nov}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_nov != "This field is required"
                            ? "form-group "
                            : "form-group has-error has-feedback"
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_nov != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_nov"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_nov}
                          required={errors.cat_nov}
                          disabled={!editable.Nov}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_nov && (
                          <div className="invalid-feedback">
                            {errors.cat_nov}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-column align-items-center">
                      <div className=" text-center" style={{ width: "90%" }}>
                        <label htmlFor="price">
                          Dec-{dateFormat(date, "yy")}
                        </label>
                        <NumberFormat
                          thousandSeparator={true}
                          step="0.1"
                          className="form-control form-control-sm"
                          name="dec"
                          value={dec || ""}
                          onChange={this.onChangeValue}
                          readOnly={!editable.Dec}
                        />
                        {errors.dec && (
                          <div className="invalid-feedback">{errors.dec}</div>
                        )}
                      </div>
                      <div
                        className={
                          errors.cat_dec != "This field is required"
                            ? "form-group "
                            : "form-group has-error has-feedback "
                        }
                        style={{ width: "90%" }}
                      >
                        <select
                          className={
                            errors.cat_dec != "This field is required"
                              ? "form-control form-control-sm"
                              : "form-control form-control-sm has-error has-feedback"
                          }
                          name="cat_dec"
                          onChange={this.onChangeValue}
                          style={{ padding: 0 }}
                          defaultValue={cat_dec}
                          required={errors.cat_dec}
                          disabled={!editable.Dec}
                        >
                          <option value="">Choose</option>
                          {select_items_category &&
                            select_items_category.map(function (item, i) {
                              return (
                                <option value={item.value} key={i}>
                                  {item.value}
                                </option>
                              );
                            })}
                        </select>

                        {errors.cat_dec && (
                          <div className="invalid-feedback">
                            {errors.cat_dec}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </Container>
              </ModalBody>
              <ModalFooter>
                {id &&
                  (editable.Jan ||
                    editable.Feb ||
                    editable.Mar ||
                    editable.Apr ||
                    editable.May ||
                    editable.Jun ||
                    editable.Jul ||
                    editable.Aug ||
                    editable.Sep ||
                    editable.Oct ||
                    editable.Nov ||
                    editable.Dec) && (
                    <Button
                      variant="success"
                      onClick={this.handleSubmitEdit}
                      className="float-right"
                    >
                      <i className="fas fa-save" /> Update
                    </Button>
                  )}
              </ModalFooter>
            </form>
          </Modal>
          <Header />
          <SideMenu />
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6"></div>
                  {/* /.col */}
                </div>
                {/* /.row */}
              </div>
              {/* /.container-fluid */}
            </div>

            <section className="content">
              <div className="container-fluid">
                {isCreate && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Sales Forecast</h3>
                        </div>
                        <div className="card-body">
                          <Tabs
                            defaultActiveKey="single"
                            title="Single"
                            variant="tabs"
                          >
                            <Tab eventKey="single" title="Single Input">
                              <form
                                autoComplete="off"
                                className={
                                  submitted_create
                                    ? "needs-validation was-validated py-2"
                                    : "needs-validation py-2"
                                }
                                noValidate
                                ref={(form) => (this.form = form)}
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                              >
                                <Row>
                                  <Col xs={12} md={4}>
                                    <div
                                      className={
                                        errors_create.segment !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Segment{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        className={
                                          errors_create.segment !=
                                          "This field is required"
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm  has-error has-feedback"
                                        }
                                        name="segment_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={segment_create}
                                        readOnly={unitreadOnly}
                                        required
                                      >
                                        <option value="">Choose</option>
                                        {select_items_segemnt &&
                                          select_items_segemnt.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div className="form-group">
                                      <label htmlFor="date">
                                        Year{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <DatePicker
                                        selected={date_create}
                                        startDate={date_create}
                                        disabledKeyboardNavigation
                                        onChange={this.handleDate.bind(
                                          this,
                                          "date_create"
                                        )}
                                        name="date"
                                        wrapperClassName="datePickerCustom"
                                        dateFormat="yyyy"
                                        className="form-control form-control-sm"
                                        required
                                        showYearPicker
                                        yearItemNumber={2}
                                      />
                                      {errors_create.date && (
                                        <div className="invalid-feedback">
                                          {errors_create.date}
                                        </div>
                                      )}
                                    </div>
                                    {/* <br></br> */}
                                  </Col>

                                  <Col xs={12} md={4}>
                                    <div
                                      className={
                                        errors_create.customer !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="customer">
                                        Sold To{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <AsyncSelect
                                        onChange={this.handleChange.bind(
                                          this,
                                          "customer_create"
                                        )}
                                        cacheOptions
                                        defaultOptions={select_items_customer}
                                        loadOptions={this.getCustomer}
                                        defaultValue={select_items_customer.filter(
                                          (e) => e.name === customer_create
                                        )}
                                        //code below to override select items from parent
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        ref={(ref) => (this.refCustomer = ref)}
                                        additional={{
                                          page: 1,
                                        }}
                                        placeholder="Select"
                                      />

                                      {!isValidCustomerCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.customer}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.material !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="material">
                                        Material FG{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <AsyncSelect
                                        onChange={this.handleChange.bind(
                                          this,
                                          "material_create"
                                        )}
                                        cacheOptions
                                        defaultOptions={select_items_material}
                                        loadOptions={this.getMaterial}
                                        defaultValue={select_items_material.filter(
                                          (e) => e.name === material_create
                                        )}
                                        //code below to override select items from parent
                                        menuPortalTarget={document.body}
                                        styles={{
                                          menuPortal: (base) => ({
                                            ...base,
                                            zIndex: 9999,
                                          }),
                                        }}
                                        ref={(ref) => (this.refMaterial = ref)}
                                        additional={{
                                          page: 1,
                                        }}
                                        placeholder="Select"
                                      />
                                      {!isValidMaterialCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.material}
                                        </div>
                                      )}
                                    </div>
                                    {/* input shipped to */}
                                    <div
                                      style={{ display: "none" }}
                                      className={
                                        errors_create.shipped_to !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="shipped_to">
                                        Shipped To
                                      </label>
                                      <Select
                                        onChange={this.handleChange.bind(
                                          this,
                                          "shipped_to_create"
                                        )}
                                        options={select_items_customer}
                                        defaultValue={select_items_customer.filter(
                                          (e) => e.name === shipped_to_create
                                        )}
                                        isClearable={isClearable}
                                        ref={(ref) => (this.refShippedTo = ref)}
                                        styles={customStylesShippedToCreate}
                                      />

                                      {!isValidShippedToCreate && (
                                        <div
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create.shipped_to}
                                        </div>
                                      )}
                                    </div>
                                  </Col>

                                  <Col xs={12} md={4}>
                                    <div className={"form-group"}>
                                      <label htmlFor="price">
                                        Qty{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        type="text"
                                        step="1"
                                        className="form-control form-control-sm"
                                        name="qty_create"
                                        placeholder="Qty"
                                        required="required"
                                        defaultValue={qty_create}
                                        onChange={this.onChangeValue}
                                        readOnly={true}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.unit !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback"
                                      }
                                    >
                                      <label htmlFor="currency">
                                        Unit{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>

                                      <select
                                        className={
                                          errors_create.unit !=
                                          "This field is required"
                                            ? "form-control"
                                            : "form-control has-error has-feedback"
                                        }
                                        name="unit_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={unit_create}
                                        readOnly={true}
                                        required
                                        ref={(ref) => (this.refUnit = ref)}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_uom &&
                                          select_items_uom.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.unit && (
                                        <div className="invalid-feedback">
                                          {errors_create.unit}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                                <hr></hr>
                                {/* ROW BULAN */}
                                <div className="d-flex flex-row justify-content-between">
                                  <div className="d-flex flex-column justify-content-around">
                                    <label htmlFor="month">Month</label>
                                    <label htmlFor="quantity">Quantity</label>
                                    <label htmlFor="category" className="mb-3">
                                      Category
                                    </label>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className="ext-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Jan-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="jan_create"
                                        value={jan_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        !errors_create.cat_jan_create
                                          ? "form-group "
                                          : "form-group has-error has-feedback"
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          !errors_create.cat_jan_create
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_jan_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_jan_create}
                                        required={errors_create.cat_jan_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_jan_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_jan_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Feb-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="any"
                                        className="form-control form-control-sm"
                                        name="feb_create"
                                        value={feb_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        !errors_create.cat_feb_create
                                          ? "form-group "
                                          : "form-group has-error has-feedback"
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          !errors_create.cat_feb_create
                                            ? "form-control form-control-sm "
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_feb_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_feb_create}
                                        required={errors_create.cat_feb_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_feb_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_feb_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Mar-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="mar_create"
                                        value={mar_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        !errors_create.cat_mar_create
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          !errors_create.cat_mar_create
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_mar_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_mar_create}
                                        required={errors_create.cat_mar_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_mar_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_mar_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Apr-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="apr_create"
                                        value={apr_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_apr_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_apr_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_apr_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_apr_create}
                                        required={errors_create.cat_apr_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_apr_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_apr_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        May-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="may_create"
                                        value={may_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_may_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_may_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_may_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_may_create}
                                        required={errors_create.cat_may_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_may_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_may_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Jun-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="jun_create"
                                        value={jun_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_jun_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_jun_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_jun_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_jun_create}
                                        required={errors_create.cat_jun_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_jun_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_jun_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div className=" text-center">
                                      <label htmlFor="price">
                                        Jul-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="jul_create"
                                        value={jul_create || ""}
                                        onChange={this.onChangeValue}
                                        min="0"
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_jul_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_jul_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_jul_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_jul_create}
                                        required={errors_create.cat_jul_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_jul_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_jul_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div className=" text-center">
                                      <label htmlFor="price">
                                        Aug-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="aug_create"
                                        value={aug_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_aug_create !=
                                        "This field is required"
                                          ? "form-group"
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_aug_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_aug_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_aug_create}
                                        required={errors_create.cat_aug_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_aug_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_aug_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Sep-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="sep_create"
                                        value={sep_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_sep_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_sep_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_sep_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_sep_create}
                                        required={errors_create.cat_sep_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_sep_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_sep_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Oct-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="oct_create"
                                        value={oct_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_oct_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_oct_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_oct_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_oct_create}
                                        required={errors_create.cat_oct_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_oct_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_oct_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Nov-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="nov_create"
                                        value={nov_create || ""}
                                        onChange={this.onChangeValue}
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_nov_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_nov_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_nov_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_nov_create}
                                        required={errors_create.cat_nov_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_nov_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_nov_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <div
                                      className=" text-center"
                                      style={{ width: "90%" }}
                                    >
                                      <label htmlFor="price">
                                        Dec-{dateFormat(date_create, "yy")}
                                      </label>
                                      <NumberFormat
                                        thousandSeparator={true}
                                        step="0.1"
                                        className="form-control form-control-sm"
                                        name="dec_create"
                                        value={dec_create || ""}
                                        onChange={this.onChangeValue}
                                        disabled={
                                          date
                                            ? new Date(
                                                dateFormat(
                                                  new Date(),
                                                  new Date(date).getFullYear() +
                                                    "-12-01"
                                                )
                                              ) -
                                                new Date(
                                                  dateFormat(
                                                    new Date(),
                                                    new Date(
                                                      date
                                                    ).getFullYear() + "-mm-01"
                                                  )
                                                ) <
                                              0
                                            : false
                                        }
                                      />
                                      {errors_create.price && (
                                        <div className="invalid-feedback">
                                          {errors_create.price}
                                        </div>
                                      )}
                                    </div>
                                    <div
                                      className={
                                        errors_create.cat_dec_create !=
                                        "This field is required"
                                          ? "form-group "
                                          : "form-group has-error has-feedback "
                                      }
                                      style={{ width: "90%" }}
                                    >
                                      <select
                                        className={
                                          errors_create.cat_dec_create !=
                                          "This field is required"
                                            ? "form-control form-control-sm"
                                            : "form-control form-control-sm has-error has-feedback"
                                        }
                                        name="cat_dec_create"
                                        onChange={this.onChangeValue}
                                        style={{ padding: 0 }}
                                        defaultValue={cat_dec_create}
                                        required={errors_create.cat_dec_create}
                                      >
                                        <option value="">Choose</option>
                                        {select_items_category &&
                                          select_items_category.map(function (
                                            item,
                                            i
                                          ) {
                                            return (
                                              <option
                                                value={item.value}
                                                key={i}
                                              >
                                                {item.value}
                                              </option>
                                            );
                                          })}
                                      </select>

                                      {errors_create.cat_dec_create && (
                                        <div className="invalid-feedback">
                                          {errors_create.cat_dec_create}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <Row>
                                  <Col xs={12} md={12}>
                                    <Button
                                      variant="success"
                                      onClick={this.handleSubmit}
                                      className="float-right m-1"
                                    >
                                      <i className="fas fa-save" /> Submit
                                    </Button>
                                    <Button
                                      variant="info"
                                      onClick={this.handleNotification}
                                      className="float-right m-1"
                                      disabled={disableButtonNotification}
                                    >
                                      <i className="fab fa-telegram-plane" />{" "}
                                      Send Notification{" "}
                                      {disableButtonNotification
                                        ? `(in ${seconds}s)`
                                        : ""}
                                    </Button>
                                  </Col>
                                </Row>
                              </form>
                            </Tab>
                            <Tab eventKey="bulk" title="Bulk Input">
                              <div className="row">
                                <div className="col-sm-12 col-md-6 p-2">
                                  <div className="form-group">
                                    <label htmlFor="exampleInputFile">
                                      Template
                                    </label>
                                    <div className="mb-2">
                                      <div key="inline-radio">
                                        <Check
                                          inline
                                          label="Template INT"
                                          name="template"
                                          type="radio"
                                          value="template1"
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              template: e.target.value,
                                              errors: {},
                                            });
                                          }}
                                          checked={template === "template1"}
                                        />
                                        <Check
                                          inline
                                          label="Template B2B"
                                          name="template"
                                          type="radio"
                                          value="template2"
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              template: e.target.value,
                                              errors: {},
                                            });
                                          }}
                                          checked={template === "template2"}
                                        />
                                      </div>
                                      {errors_create_bulk.template && (
                                        <em
                                          style={{
                                            width: "100%",
                                            marginTop: ".25rem",
                                            fontSize: "80%",
                                            color: " #dc3545",
                                          }}
                                        >
                                          {errors_create_bulk.template}
                                        </em>
                                      )}
                                    </div>
                                    <label htmlFor="exampleInputFile">
                                      File input
                                    </label>
                                    <div className="input-group">
                                      <div className="custom-file">
                                        <input
                                          disabled={!template}
                                          type="file"
                                          className="custom-file-input"
                                          id="exampleInputFile"
                                          onChange={this.handleBulkFile}
                                          accept={SheetJSFT}
                                          value={file_value}
                                          key={inputFile || ""}
                                        />
                                        <label
                                          className="custom-file-label"
                                          htmlFor="exampleInputFile"
                                        >
                                          {file.name}
                                        </label>
                                      </div>
                                    </div>
                                    <div className="mt-2 w-50">
                                      <div className="w-50">
                                        <label htmlFor="month_start">
                                          Year
                                        </label>
                                        <DatePicker
                                          selected={month_start}
                                          onChange={this.handleBulkYear}
                                          name="month_start"
                                          wrapperClassName="datePickerCustom"
                                          className="form-control w-50"
                                          showYearPicker
                                          dateFormat="yyyy"
                                        />
                                      </div>
                                    </div>
                                    {errors_create_bulk?.excel && (
                                      <div
                                        style={{
                                          width: "100%",
                                          marginTop: ".25rem",
                                          fontSize: "80%",
                                          color: " #dc3545",
                                        }}
                                      >
                                        {errors_create_bulk?.excel}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                              {load_file && (
                                <div className="row">
                                  <div className="col-sm-12 col-md-12 p-2">
                                    <WorksheetTabCustom
                                      data={file_worksheet}
                                      template={template}
                                    />
                                  </div>
                                </div>
                              )}
                              <div className="row">
                                <div className="col-sm-12 col-md-6 p-2">
                                  <Button
                                    variant="success"
                                    className="float-right m-1"
                                    onClick={this.handleBulkSubmit}
                                  >
                                    <i className="fas fa-save" /> Submit
                                  </Button>
                                  <Button
                                    variant="danger"
                                    className="float-right m-1"
                                    onClick={this.handleBulkClear}
                                  >
                                    <i className="fas fa-eraser" /> Clear
                                  </Button>
                                </div>

                                <div className="col-sm-12 col-md-6 p-2 mt-1">
                                  <>
                                    {" "}
                                    <ButtonGroup>
                                      <DropdownButton
                                        as={ButtonGroup}
                                        title="Download Template"
                                        id="bg-nested-dropdown"
                                      >
                                        <Dropdown.Item
                                          href={`${process.env.PUBLIC_URL}/template_int.xlsx`}
                                        >
                                          Template INT{" "}
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="2"
                                          href={`${process.env.PUBLIC_URL}/template_b2b.xlsx`}
                                        >
                                          Template B2B{" "}
                                        </Dropdown.Item>
                                      </DropdownButton>
                                    </ButtonGroup>
                                  </>

                                  <Button
                                    variant="info"
                                    onClick={this.handleNotification}
                                    className="float-right m-1"
                                    disabled={disableButtonNotification}
                                  >
                                    <i className="fab fa-telegram-plane" /> Send
                                    Notification{" "}
                                    {disableButtonNotification
                                      ? `(in ${seconds}s)`
                                      : ""}
                                  </Button>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isRead && (
                  <div className="row">
                    <div className="col-md-12 col-sm-12 col-12">
                      <div className="card card-info">
                        <div className="card-header ">
                          <h3 className="card-title">Historical Data</h3>
                          <div className="card-tools float-right"></div>
                        </div>
                        <div className="card-body">
                          <form
                            autoComplete="off "
                            onSubmit={(e) => e.preventDefault()}
                          >
                            <Row>
                              <Col xs={12} md={1}>
                                <label htmlFor="year">Year </label>
                              </Col>
                              <Col xs={12} md={1}>
                                <label htmlFor="filter_by">Filter By </label>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12} md={1}>
                                <Select
                                  onChange={this.handleChange.bind(
                                    this,
                                    "year_filter"
                                  )}
                                  options={forecast_year}
                                  defaultValue={forecast_year.filter(
                                    (e) => e.label === year_filter
                                  )}
                                  value={
                                    forecast_year.find(
                                      (e) => e.label === year_filter
                                    ) || null
                                  }
                                  isClearable
                                  placeholder="Select Year..."
                                  styles={customStyles}
                                />
                              </Col>

                              <Col xs={12} md={2}>
                                <div className="form-row form-group">
                                  <select
                                    className="form-control form-control-sm"
                                    name="filter_by"
                                    onChange={this.onChangeValue}
                                    style={{ padding: 0 }}
                                    defaultValue={filter_by}
                                  >
                                    <option value="">Choose</option>
                                    <option value="segment">Segment</option>
                                    <option value="customer">Sold To</option>
                                    <option value="material">
                                      Material Code FG
                                    </option>
                                  </select>
                                </div>
                              </Col>
                              <Col xs={12} md={2}>
                                <div className="form-group">
                                  <input
                                    className="form-control form-control-sm"
                                    name="search"
                                    value={search || ""}
                                    placeholder="Search ..."
                                    onChange={this.onChangeValue}
                                    onKeyPress={(e) => {
                                      if (e.key == "Enter") {
                                        this.handleSearch();
                                      }
                                    }}
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Button
                                  variant="info"
                                  onClick={this.handleSearch}
                                  className="float-left"
                                >
                                  <i className="fas fa-search" /> Search
                                </Button>
                              </Col>
                              <Col>
                                {loading_download_history ? (
                                  <Button
                                    variant="primary"
                                    className="m-1 float-right"
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>{" "}
                                    Loading...
                                  </Button>
                                ) : (
                                  <Button
                                    variant="primary"
                                    onClick={this.handleDownload}
                                    className="float-right"
                                  >
                                    <i className="fas fa-file" /> Download
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          </form>
                          <br />
                          {!isLoading ? (
                            <RemoteAll
                              data={list_datatable}
                              columns={columns}
                              defaultSorted={defaultSorted}
                              cellEditProps={cellEditProps}
                              page={page}
                              sizePerPage={sizePerPage}
                              totalSize={totalSize}
                              onTableChange={this.handleTableChange.bind(this)}
                            />
                          ) : (
                            <div className="d-flex flex-row align-items-center justify-content-center">
                              <Spinner animation="border" variant="info" />
                              <h5 className="ml-2">Loading...</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {/* /.col */}
                  </div>
                )}
              </div>
              {/* /.container-fluid */}
            </section>
            {/* /.content */}
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withRouter(SalesForecast);
