import axios from "axios";
import authHeader from "../auth-header";

const API_URL_DEFAULT = process.env.REACT_APP_API;
const API_MODULE = "price_list_tracing";

class PriceListTracingService {
  check(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE, payload, {
      headers: authHeader(),
    });
  }
  download(payload) {
    return axios.post(API_URL_DEFAULT + API_MODULE + "/download", payload, {
      headers: authHeader(),
      responseType: "blob",
    });
  }
}
export default new PriceListTracingService();
