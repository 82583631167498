import React, { useState } from "react";
import Swal from "sweetalert2";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import dateFormat from "dateformat";
import { addMonths } from "date-fns";
import Modal from "react-bootstrap/Modal";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import DecimalPrecision from "../helpers/decimalprecision";
import { Col, ModalFooter, Row } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import FileDownload from "js-file-download";
import { check, download } from "../modules/sodiq/PriceListTracingModule";

const DECIMAL_PLACES = parseInt(process.env.REACT_APP_DECIMAL_PLACES);

const PriceListMonthly = ({ data, planning_month, tracing = false }) => {
  const headerStyle = { backgroundColor: "#17A2B8", color: "white" };
  const detailStyle = { color: "#0052CC", cursor: "pointer" };

  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenDetail, setOpenDetail] = useState([]);
  const [dataDetail, setDataDetail] = useState([]);
  const [title, setTitle] = useState("");
  const [indexHead, setIndexHead] = useState(null);

  const renderHeaderMonth = () => {
    let list_date = [];
    let date = new Date(planning_month);
    for (let i = 0; i < 6; i++) {
      list_date.push(<th>{dateFormat(addMonths(date, i), "mmm-yy")}</th>);
    }
    return list_date;
  };

  const detail = (name, row, index) => {
    const datas = data[row]["detail_price" + index];
    setTitle(
      `${name} ${dateFormat(
        addMonths(new Date(planning_month), index),
        "mmm-yy"
      )}`
    );
    setIndexHead(index);

    if (datas) {
      setDataDetail(datas);
    }

    if (tracing) {
      setLoading(true);
      check({
        header_code: data[row]?.material_code,
        material: datas,
        month: dateFormat(
          addMonths(new Date(planning_month), index),
          "yyyy-mm-01"
        ),
        status: data[row]?.status,
      })
        .then((res) => {
          setDataDetail([...dataDetail, res]);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
      setOpenDetail([...isOpenDetail, true]);
    } else {
      setOpen(true);
    }
  };

  const detailChild = (material_code) => {
    setLoading(true);
    check({
      header_code: material_code,
      material: [],
      month: dateFormat(
        addMonths(new Date(planning_month), indexHead),
        "yyyy-mm-01"
      ),
      index: indexHead,
    })
      .then((res) => {
        setDataDetail([...dataDetail, res]);
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false);
      });
    setOpenDetail([...isOpenDetail, true]);
  };

  const closeModal = (index) => {
    const newOpenModals = [...isOpenDetail];
    newOpenModals.splice(index, 1);
    setOpenDetail(newOpenModals);

    const newModalDetails = [...dataDetail];
    newModalDetails.splice(index, 1);
    setDataDetail(newModalDetails);
  };

  const formatNumber = (number) => {
    return isNaN(parseFloat(number))
      ? Number(0).toFixed(DECIMAL_PLACES)
      : Number(number).toFixed(DECIMAL_PLACES);
  };

  const handleDownload = async (payload, idx) => {
    try {
      const name = payload?.header?.no_material || "material";
      const selected = dateFormat(
        addMonths(new Date(planning_month), indexHead),
        "mmm-yy"
      );
      const index = idx + 1;
      const result = await download({ ...payload, name, selected, index });
      const fileName = `#${index}_${name}_${selected}.xlsx`;
      FileDownload(result, fileName);
    } catch (e) {
      Swal.fire("Warning", e, "error");
    }
  };

  return (
    <>
      <div key={data}>
        <Form>
          <Table
            bordered
            hover
            size="sm"
            style={{ textAlign: "center", justifyContent: "center" }}
          >
            <thead className="Stick to top">
              <tr style={headerStyle}>
                <th>No</th>
                <th>Material Code</th>
                <th>Material Name</th>
                <th>Facility</th>
                <th>Status</th>
                {renderHeaderMonth()}
              </tr>
            </thead>
            <tbody>
              {data.map((item, idx) => (
                <tr>
                  <td>{idx + 1}</td>
                  <td>{item.material_code}</td>
                  <td>{item.material_name}</td>
                  <td>
                    {item.type}
                    {item.plant ? " " + item.plant : ""}
                  </td>
                  <td>{item.status}</td>
                  <td>
                    {isNaN(parseFloat(item.val0)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 0)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 0)}
                      >
                        {item.val0.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val1)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 1)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 1)}
                      >
                        {item.val1.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val2)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 2)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 2)}
                      >
                        {item.val2.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val3)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 3)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 3)}
                      >
                        {item.val3.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val4)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 4)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 4)}
                      >
                        {item.val4.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                  <td>
                    {isNaN(parseFloat(item.val5)) ? (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 5)}
                      >
                        <i class="fa fa-info-circle" aria-hidden="true"></i>
                      </a>
                    ) : (
                      <a
                        style={detailStyle}
                        onClick={() => detail(item.material_name, idx, 5)}
                      >
                        {item.val5.toFixed(DECIMAL_PLACES)}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Form>
      </div>
      <Modal show={isOpen} size="lg" backdrop="static" onHide={setOpen}>
        <ModalHeader closeButton className="bg-info text-white">
          <ModalTitle>Detail {title}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Container>
            <table className="table table-bordered">
              <thead>
                <tr style={headerStyle}>
                  <th>Material Desc</th>
                  <th>Price/KG</th>
                  <th>Amount/Batch</th>
                  <th>Total</th>
                  <th>Plant</th>
                </tr>
              </thead>
              <tbody>
                {dataDetail && dataDetail.length > 0 ? (
                  dataDetail.map((item) => {
                    return (
                      <tr>
                        <td>{item.material_description_rm}</td>
                        <td>
                          {!isNaN(item.price) && item.price !== ""
                            ? DecimalPrecision.toFixed(
                                item.price,
                                DECIMAL_PLACES
                              )
                            : "not found"}
                        </td>
                        <td>{item.amount_per_batch}</td>
                        <td>
                          {!isNaN(item.total) && item.total !== ""
                            ? DecimalPrecision.toFixed(
                                item.total,
                                DECIMAL_PLACES
                              )
                            : "not found"}
                        </td>
                        <td>{item.plant || "not found"}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={2}>Data Not Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </Container>
        </ModalBody>
      </Modal>

      {isOpenDetail?.map((isOpen, index) => (
        <Modal
          show={isOpen}
          size="lg"
          backdrop="static"
          onHide={() => closeModal(index)}
        >
          <ModalHeader closeButton className="bg-info text-white">
            <ModalTitle>
              #{index + 1} Detail{" "}
              {dataDetail[index]?.header?.material_description}{" "}
              {dateFormat(
                addMonths(new Date(planning_month), indexHead),
                "mmm-yy"
              )}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Container>
              <Row>
                {dataDetail[index]?.notes && (
                  <Col xs={12} md={12}>
                    <Alert variant="danger">{dataDetail[index]?.notes}</Alert>
                  </Col>
                )}
                <Col xs={12} md={12}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td width="24%" className="fw-bold">
                          FG BOM
                        </td>
                        <td align="center" className="fw-bold">
                          {dataDetail[index]?.header?.material_description}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={6}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td width="50%" className="fw-bold">
                          Header QTY
                        </td>
                        <td>{formatNumber(dataDetail[index]?.header_qty)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Prod Failicty</td>
                        <td>{dataDetail[index]?.header?.prod_facility}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">Machine Capacity</td>
                        <td>
                          {formatNumber(
                            dataDetail[index]?.header?.machine_capacity
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xs={6} md={6}>
                  <Button
                    variant="success"
                    className="float-right"
                    onClick={() => handleDownload(dataDetail[index], index)}
                  >
                    <i className="fas fa-download"></i> Export Excel
                  </Button>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">Material Ingredient</th>
                        <th className="text-center">Qty (kg)</th>
                        <th className="text-center">MAP (USD/kg)</th>
                        <th className="text-center">Price (USD)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDetail[index]?.material &&
                        dataDetail[index]?.material?.length > 0 &&
                        dataDetail[index]?.material.map((item) => (
                          <tr>
                            <td width="50%">
                              {item?.is_click ? (
                                <a
                                  style={detailStyle}
                                  onClick={() =>
                                    detailChild(item.no_material_rm)
                                  }
                                >
                                  {item?.material_description_rm}
                                </a>
                              ) : (
                                item?.material_description_rm
                              )}
                            </td>
                            <td align="right">
                              {formatNumber(item?.amount_per_batch)}
                            </td>
                            <td align="right">{formatNumber(item?.price)}</td>
                            <td align="right">{formatNumber(item?.total)}</td>
                          </tr>
                        ))}

                      <tr>
                        <td className="fw-bold" align="center" colSpan={3}>
                          Total
                        </td>
                        <td className="fw-bold" align="right">
                          {formatNumber(dataDetail[index]?.total_price)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={12}>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">resource category</th>
                        <th className="text-center">resource qty</th>
                        <th className="text-center">resource per batch</th>
                        <th className="text-center">cost rate</th>
                        <th className="text-center">value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataDetail[index]?.resource &&
                        dataDetail[index]?.resource?.length > 0 &&
                        dataDetail[index]?.resource.map((item) => (
                          <tr>
                            <td>
                              {item?.resource_code} {item?.resource_desc}
                            </td>
                            <td align="right">
                              {formatNumber(item?.resource_qty)}
                            </td>
                            <td align="right">
                              {formatNumber(item?.per_batch)}
                            </td>
                            <td align="right">
                              {formatNumber(item?.cost_rate)}
                            </td>
                            <td align="right">{formatNumber(item?.value)}</td>
                          </tr>
                        ))}
                      <tr>
                        <td className="fw-bold" align="center" colSpan={4}>
                          Total
                        </td>
                        <td className="fw-bold" align="right">
                          {formatNumber(dataDetail[index]?.total_resource)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>

              <Row>
                <Col xs={6} md={6}>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td width="50%" className="fw-bold">
                          factor
                        </td>
                        <td>{formatNumber(dataDetail[index]?.factor)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">grandtotal</td>
                        <td>{formatNumber(dataDetail[index]?.grandtotal)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">cogm</td>
                        <td>{formatNumber(dataDetail[index]?.cogm)}</td>
                      </tr>
                      <tr>
                        <td className="fw-bold">cogs</td>
                        <td>{formatNumber(dataDetail[index]?.cogs)}</td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Container>
          </ModalBody>
          <ModalFooter>
            <Button variant="default" onClick={() => closeModal(index)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      ))}
      <Modal
        show={loading}
        size="xl"
        backdrop="static"
        onHide={() => setLoading(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        keyboard={false}
      >
        <ModalHeader className="bg-info text-white"></ModalHeader>
        <ModalBody>
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <ProgressBar animated now={100} />
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PriceListMonthly;
